import { Duration } from "@squeeze-io/database";
import { group } from "console";

const defaultFiltersEnabled = {
  hasDateFilter: true,
  hasCompetitorFilter: true,
  hasSourceFilter: true,
  hasLocationFilter: true,
  hasFunctionalGroupFilter: true,
};

const competitiveBenchmarkFilters = {
  hasDateFilter: true,
  hasCompetitorFilter: false,
  hasSourceFilter: true,
  hasLocationFilter: true,
  hasFunctionalGroupFilter: true,
};
export const defaultFiltersDisabled = {
  hasDateFilter: false,
  hasCompetitorFilter: false,
  hasSourceFilter: false,
  hasLocationFilter: false,
  hasFunctionalGroupFilter: false,
};

export const pathWiseFilterData = {
  "/discover": defaultFiltersEnabled,
  "/discover/performance-assessment": defaultFiltersEnabled,
  "/discover/competitive-benchmark": competitiveBenchmarkFilters,
  "/discover/sentiment-breakdown": defaultFiltersEnabled,
  "/discover/sentiment-tracker": defaultFiltersEnabled,
  "/discover/emotive-analysis": {
    ...defaultFiltersEnabled,
    hasCompetitorFilter: false,
  },
  "/discover/trend-insights": {
    ...defaultFiltersEnabled,
    hasCompetitorFilter: false,
  },
};

export function getDurationString(duration: number = 36) {
  switch (duration) {
    case 3:
      return Duration.LAST_QUARTER;
    case 12:
      return Duration.LAST_YEAR;
    case 36:
      return Duration.LAST_THREE_YEARS;
    default:
      return Duration.LAST_THREE_YEARS;
  }
}

export const sourcePlatforms = [
  { label: "Glassdoor", value: "glass-door" },
  { label: "Indeed", value: "indeed" },
  // { label: "Teamblind", value: "team-blind" }, // Once the data is available, we can enable this
];

export const FUNCTIONAL_GROUPS_OPTIONS: Option[] = [
  { label: "Customer Success", value: "customer_success" },
  { label: "Frontline Workers", value: "frontline_workers" },
  { label: "General & Administrative", value: "general_&_administrative" },
  { label: "Marketing", value: "marketing" },
  { label: "Product & Engineering", value: "product_&_engineering" },
  { label: "Sales", value: "sales" },
  { label: "Others", value: "other" },
];

export const CATEGORIES_OPTIONS: Option[] = [
  { label: "Balance & Wellbeing", value: "balance_&_wellbeing" },
  { label: "Benefits & Perks", value: "benefits_&_perks" },
  { label: "Career Progression", value: "career_progression" },
  { label: "Company Culture", value: "company_culture" },
  { label: "Diversity & Inclusion", value: "diversity_&_inclusion" },
  { label: "Innovation & Technology", value: "innovation_&_technology" },
  { label: "Job Satisfaction", value: "job_satisfaction" },
  { label: "Learning & Development", value: "learning_&_development" },
  { label: "Management & Organization", value: "management_&_organization" },
  { label: "Mission & Purpose", value: "mission_&_purpose" },
  { label: "Remuneration", value: "remuneration" },
  { label: "Team & People", value: "team_&_people" },
];

export const TYPE_OPTIONS: Option[] = [
  { label: "Survey", value: "SURVEY" },
  { label: "Focus Group", value: "FOCUS_GRUOP" },
  { label: "Interviews", value: "INTERVIEWS" },
];

export interface Option {
  value: string;
  label: string;
  disable?: boolean;
  /** fixed option that can't be removed. */
  fixed?: boolean;
  /** Group the options by providing key. */
  [key: string]: string | boolean | undefined;
}

export const getSelectedLabel = (options: Option[], value: string) => {
  return options.find((option: Option) => option.value === value)?.label || "";
};

export const AI_SUMMARY_DESC =
  "AI-output generated based on Company’s employer brand data and Squeeze’s proprietary algorithms.";

export const CHART_DESCRIPTION = {
  PERFORMANCE_ASSESSMENT:
    "Evaluate the overall performance and effectiveness of your employer brand sentiment by time period, location, and functional group.",
  SENTIMENT_BREAKDOWN:
    "Analyze your employer brand using benchmarks, rankings, and quotes, alongside comparisons to talent competitors.",
  SENTIMENT_TRACKER:
    "Monitor real-time changes and trends in employer brand sentiment across various data sources.",
  EMOTIVE_ANALYSIS:
    "Analyze the emotional undertones in feedback to understand deeper feelings and perceptions.",
  TRENDING_THEMES:
    "Identify and explore the most discussed topics and recurring themes in employer brand conversations.",
  COMPETITIVE_BENCHMARK:
    "View your sentiment percentage across 12 Employer Brand Attributes compared to your talent competitors",
};

export const DATA_MANAGER_UPLOAD_FILE_MAX_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
export const S3_PRE_SIGNED_URL_EXPIRE = 3600;
export const DATA_MANAGER_FILE_ALLOWED_MIME_TYPES = [
  "application/doc",
  "application/ms-doc",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
];

export const FIVETRAN_CONFIG = {
  SYNC_FREQUENCY: 1440,
  DATA_DELAY_SENSITIVITY: "NORMAL",
  DAILY_SYNC_TIME: "03:00",
  PAUSED: false,
  PAUSE_AFTER_TRIAL: false,
};

export const LINKEDIN_POST_PERFORMANCE_RECORDS = 5;

export const formatLargeNumber = (value: number) => {
  const absValue = Math.abs(value);
  let formattedValue: string;

  if (absValue >= 1_000_000_000) {
    formattedValue =
      (absValue / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (absValue >= 1_000_000) {
    formattedValue =
      (absValue / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (absValue >= 1_000) {
    formattedValue = (absValue / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    formattedValue = absValue.toFixed(0);
  }

  // Add negative sign back if original value was negative
  return value < 0 ? `-${formattedValue}` : formattedValue;
};

export const REVIEW_SITE_RATINGS_DURATION = 12;

export const LINKEDIN_POST_URL = "https://www.linkedin.com/feed/update";
