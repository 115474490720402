import {
  Linkedin,
  LucideProps,
  SunMedium,
  LogOut,
  LogIn,
  Zap,
  CloudLightning,
  LayoutGrid,
  Lightbulb,
  Newspaper,
  Bot,
  ClipboardList,
  Fingerprint,
  Shield,
  Rocket,
  Lock,
  Pencil,
  Trash2Icon,
  TimerReset,
  Eye,
  ArrowDownRight,
  ArrowDownUp,
  Building,
  Settings,
  PresentationIcon,
  FolderCog,
  ChevronRight,
  User,
  Info,
  ListFilter,
  RotateCw,
  CircleCheck,
  Upload,
  Users,
  Database,
  ClipboardPenLine,
  File,
  X as cross,
  Building2,
  UsersRound,
  Plug2,
  ArrowUpRight,
  CloudDownload,
  Play,
  MegaphoneIcon,
  UserCircleIcon,
  LightbulbIcon,
  SmileIcon,
  RadarIcon,
  HeartPulseIcon,
  GaugeIcon,
} from "lucide-react";

export const Icons = {
  sun: SunMedium,
  linkedin: Linkedin,
  logOut: LogOut,
  logIn: LogIn,
  locked: Lock,
  dashboard: LayoutGrid,
  insights: Lightbulb,
  feed: Newspaper,
  surveys: ClipboardList,
  identity: Fingerprint,
  bot: Bot,
  zap: Zap,
  cloudLightning: CloudLightning,
  shield: Shield,
  rocket: Rocket,
  pencil: Pencil,
  trash: Trash2Icon,
  timerReset: TimerReset,
  eye: Eye,
  arrowDownRight: ArrowDownRight,
  arrowDownUP: ArrowDownUp,
  orgs: Building,
  platforms: PresentationIcon,
  settings: Settings,
  scrapes: FolderCog,
  chevronRight: ChevronRight,
  info: Info,
  listFilter: ListFilter,
  rotateCw: RotateCw,
  circleCheck: CircleCheck,
  user: UserCircleIcon,
  users: Users,
  usersRound: UsersRound,
  upload: Upload,
  database: Database,
  clipboardPenLine: ClipboardPenLine,
  file: File,
  x: cross,
  building2: Building2,
  plug2: Plug2,
  arrowUpRight: ArrowUpRight,
  cloudDownload: CloudDownload,
  play: Play,
  megaphone: MegaphoneIcon,
  lightbulb: LightbulbIcon,
  smile: SmileIcon,
  radar: RadarIcon,
  heartPulse: HeartPulseIcon,
  gauge: GaugeIcon,
  logo: (props: LucideProps) => (
    <svg viewBox="0 0 59.02 88.35" {...props}>
      <g fillRule="evenodd">
        <path
          fill="#25374c"
          d="M48.74,16.71l-21.18,21.18h17.53l-13.42,13.42H3.43c-3.05,0-4.58-3.69-2.42-5.85L45.99.47c1.01-1.01,2.75-.3,2.75,1.14v15.09Z"
        />
        <path
          fill="#30c6be"
          d="M56.7,51.31l-36.39,36.39c-1.39,1.39-3.77.41-3.77-1.56v-5.47c0-5.23,2.08-10.26,5.78-13.96l28.82-28.82h0c7,0,10.51,8.47,5.56,13.42Z"
        />
      </g>
    </svg>
  ),
  lineChartIcon: (props: LucideProps) => (
    <svg
      viewBox="0 0 21 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4H20.087"
        stroke={props?.color || "#A3E635"}
        strokeWidth="2"
      />
      <path
        d="M10.0459 6.65809H10.0458L10.0429 6.6581C10.0427 6.6581 10.0426 6.6581 10.0424 6.6581C9.24498 6.65799 8.49316 6.36212 7.94918 5.85532C7.40748 5.35064 7.11761 4.68182 7.11743 4.00062C7.11725 3.31943 7.40676 2.6505 7.94816 2.14559C8.49194 1.63846 9.24377 1.34223 10.0414 1.34186C10.839 1.34149 11.5912 1.63702 12.1356 2.14369C12.6775 2.64814 12.9678 3.31684 12.9683 3.99804C12.9689 4.67923 12.6797 5.34827 12.1386 5.85341C11.5951 6.36077 10.8435 6.65735 10.0459 6.65809Z"
        fill={"white"}
        stroke={props?.color || "#A3E635"}
        strokeWidth="2"
      />
    </svg>
  ),
};
